import { Menu, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import { avatarProfile } from '../../helpers'
import { Avatar } from '../Avatar/Avatar'
import { HeaderNavigation } from './Header'

interface Props {
  user: any
  history: any
  userNavigation: HeaderNavigation[]
  logout: () => Promise<void>
}

const HeaderMenu: React.FC<Props> = ({
  user,
  history,
  userNavigation,
  logout,
}) => {
  return (
    <Menu as="div" className="ml-3 relative" style={{ zIndex: 2 }}>
      <div>
        <Menu.Button className="max-w-xs bg-white-600 hover:bg-lightGrey rounded-full flex items-center text-sm focus:outline-none">
          <Avatar
            profile={avatarProfile(user)}
            textStyle="text-lg"
            containerStyle="inline-flex items-center justify-center h-10 w-10 rounded-full"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <button
                  onClick={() =>
                    item.href ? history.push(item.href) : logout()
                  }
                  className={
                    'block px-4 py-2 m-0 text-sm text-gray-700 hover:no-underline w-full text-left' +
                    (active ? ' bg-gray-100' : '')
                  }
                >
                  {item.name}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default HeaderMenu
