import { Route, Redirect } from 'react-router-dom'
import { MIN_WIDTH } from './constants'
import { useUser, useWindowWidth } from './helpers'
import { authService } from './services'
import { ScreenSizeError } from './components/ScreenSizeError'
import LoadingUser from './components/LoadingUser'

export default function PrivateRoute({ component: Component, ...rest }) {
  const size = useWindowWidth()
  const user = useUser()

  return (
    <Route
      {...rest}
      render={(props) => {
        const { token, next_page } = authService
        if (!token?.accessToken || next_page !== 'all') {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }
        if (!user) {
          return <LoadingUser user={user} />
        }
        if (size > MIN_WIDTH) {
          return <Component {...props} />
        } else {
          return <ScreenSizeError />
        }
      }}
    />
  )
}
