import './theme/style.css'
import './css/tailwind.css'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import App from './App'
import i18n from './i18n'
import reportWebVitals from './reportWebVitals'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './css/toastifyStyling.css'
import './css/passwordInput.css'
import './css/dotFlashing.css'

//import 'typeface-roboto'
export default ReactDOM.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar
      closeOnClick={false}
      rtl={false}
      pauseOnHover
      transition={Slide}
      limit={1}
    />
    <App />
  </I18nextProvider>,
  // </React.StrictMode>,
  document.getElementById('root') || document.createElement('div')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
