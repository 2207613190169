import React, { useEffect, useRef } from 'react'
import { TFunction, withTranslation } from 'react-i18next'
import LoadingBar from 'react-top-loading-bar'
import { colors } from '../theme'

const barHeight = 8

interface Props {
  user: any
  t: TFunction
}

const Loading: React.FC<Props> = ({ user, t }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (!user) ref.current.continuousStart()
    else ref.current.complete()
  }, [user])

  return (
    <React.Fragment>
      <LoadingBar
        color={colors.purple}
        ref={ref}
        height={barHeight}
        shadow={true}
      />
      <div
        className="flex flex-col justify-center items-center space-y-4"
        style={{ height: '85vh' }}
      >
        <div className="dot-flashing"></div>
        <span className="text-labelGrey text-sm">{t('loading.message')}</span>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Loading)
