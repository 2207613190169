import { Colors, Gutters, Breakpoints } from '../model/styles'

export const breakpoints: Breakpoints = {
  xs: 0,
  sm: 360,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
}

export const gutters: Gutters = {
  container: {
    sm: 20,
    md: 30,
    lg: 70,
  },
  column: {
    sm: 20,
    md: 20,
    lg: 20,
  },
}

export const colors: Colors = {
  warmGrey: '#828282',
  paleGrey: '#f6f7fb',
  white: '#FFFFFF',
  greenOnDark: '#009688',
  success: '#30A74B',
  focus: '#40d4d4',
  borderGrey: '#c6c4d2',
  borderCard: '#ebebf1',
  darkNext: '#0F0F0F',
  dangerRed: '#EB5757',
  darkGreyNext: '#969999',
  lightGrey: '#F0F0F0',
  darkGrey: '#333333',
  black: '#000000',
  primaryLight: '#8936ef',
  primaryLightDisabled: '#8936ef80',
  primary: '#462479',
  sidebar: '#fbfcff',
  orange: '#ff8d3b',
  purple: '#482474',
  purpleHover: '#7957A4',
  lightPurple: '#b386eb',
  labelGrey: '#374151',
  labelLightGrey: '#6b7280',
  barGrey: '#f3f4f6',
}

export const fonts = {
  fontFamily: 'Roboto, sans-serif',
  fontUrl:
    'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
}

export const messageColors = {
  success: colors.success,
  error: colors.dangerRed,
}
