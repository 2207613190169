import React from 'react'
import styled from 'styled-components'
import { GlobalStyleProps } from '../model/styles'
import { useTranslation } from 'react-i18next'

const FooterContainer = styled.div`
  background-color: ${(props: GlobalStyleProps) => props.theme.colors.white};
  color: ${(props: GlobalStyleProps) => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 ${(props: GlobalStyleProps) => props.theme.gutters.container.md}px;
`

const FooterText = styled.span`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.warmGrey};
  };
`

const Footer: React.FC = () => {
  const { t } = useTranslation()

  return (
    <FooterContainer id="footer">
      <FooterText>{t('footer.text')}</FooterText>
    </FooterContainer>
  )
}
export default Footer
