import React, { useContext } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { ROUTES, MIN_WIDTH, BURGER_MIN_WIDTH } from '../../constants'
import { AuthenticationContext } from '../../helpers/Authentication'
import { useUser, useWindowWidth } from '../../helpers'
import { authService } from '../../services'
import { Disclosure } from '@headlessui/react'
import HeaderMenu from './HeaderMenu'
import HeaderLinks from './HeaderLinks'
import BurgerMenu from './BurgerMenu'

export interface HeaderNavigation {
  name: string
  href?: string
}

const navigation: HeaderNavigation[] = [
  { name: 'Reporting', href: ROUTES.DASHBOARD },
  { name: 'Customers', href: ROUTES.CUSTOMER_MANAGEMENT },
  { name: 'Users', href: ROUTES.USER_MANAGEMENT },
  { name: 'System Parameters', href: ROUTES.SYSTEM_PARAMETERS },
  { name: 'Configuration', href: ROUTES.CONFIGURATION },
  { name: 'Import/Export', href: ROUTES.IMPORT_EXPORT },
  { name: 'Upload Customers', href: ROUTES.UPLOAD_CUSTOMERS },
]
const userNavigation: HeaderNavigation[] = [
  { name: 'Change Password', href: ROUTES.CHANGE_PSSW },
  // { name: '2 Factor Auth.', href: ROUTES.TWO_FACTOR },
  { name: 'Logout' },
]

const Header: React.FC = () => {
  const location = useLocation()
  const size = useWindowWidth()
  const history = useHistory()

  const user = useUser()

  const context = useContext(AuthenticationContext)
  const logout = () =>
    authService.logout(context.logout).then(() => {
      history.push(ROUTES.LOGIN)
    })

  return (
    <div className="min-h-full">
      {user && size > MIN_WIDTH && (
        <Disclosure as="nav" className="bg-white border-b border-gray-200">
          <div className="mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="flex-shrink-0 flex items-center">
                  <Link to={ROUTES.HOME}>
                    <img src="../logo.svg" alt="CSSNG" />
                  </Link>
                </div>
                {size > BURGER_MIN_WIDTH && (
                  <HeaderLinks navigation={navigation} location={location} />
                )}
              </div>

              {size > BURGER_MIN_WIDTH ? (
                <div className="sm:ml-6 sm:flex sm:items-center">
                  <HeaderMenu
                    user={user}
                    userNavigation={userNavigation}
                    history={history}
                    logout={logout}
                  />
                </div>
              ) : (
                <div className="sm:ml-6 sm:flex sm:items-center text-purple">
                  <BurgerMenu
                    user={user}
                    userNavigation={navigation.concat(userNavigation)}
                    history={history}
                    logout={logout}
                  />
                </div>
              )}
            </div>
          </div>
        </Disclosure>
      )}
    </div>
  )
}
export default Header
