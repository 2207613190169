import React from 'react'
import { colorForString } from '../../helpers'

interface Props {
  profile: {
    firstname: string
    lastname: string
    username: string
  }
  textStyle: string
  containerStyle: string
  status?: boolean
  hasStatusIndicator?: boolean
  indicatorStyle?: string
}

export const Avatar: React.FC<Props> = (props) => {
  const {
    profile,
    textStyle,
    containerStyle,
    hasStatusIndicator = false,
    status,
    indicatorStyle,
  } = props

  return (
    <div className="relative">
      <span
        className={containerStyle}
        style={{
          backgroundColor: colorForString(profile.username),
        }}
      >
        <span className={textStyle + ' leading-none text-white'}>
          {profile.lastname || profile.firstname
            ? profile.firstname?.charAt(0).toUpperCase() +
              profile.lastname?.charAt(0).toUpperCase()
            : ''}
        </span>
        {hasStatusIndicator && status && indicatorStyle ? (
          status ? (
            <span className={indicatorStyle + ' bg-focus'} />
          ) : (
            <span className={indicatorStyle + ' bg-red-400'} />
          )
        ) : null}
      </span>
      <span
        className="absolute inset-0 shadow-inner rounded-full"
        aria-hidden="true"
      />
    </div>
  )
}
