import React from 'react'
import { Link } from 'react-router-dom'
import {
  CSS_MODE,
  CSS_STACK,
  EXUS,
  ROUTES,
  STANDALONE_S3,
} from '../../constants'
import { HeaderNavigation } from './Header'

interface Props {
  location: any
  navigation: HeaderNavigation[]
}

const HeaderLinks: React.FC<Props> = ({ navigation, location }) => {
  return (
    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
      {navigation.map((item) =>
        (item.name === 'Users' && CSS_STACK !== EXUS) ||
        (item.name === 'Upload Customers' &&
          CSS_MODE !== STANDALONE_S3) ? null : (
          <Link
            key={item.name}
            to={item.href}
            className={
              'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium' +
              (location.pathname.endsWith(item.href) ||
              (location.pathname.endsWith('/') &&
                item.href === ROUTES.DASHBOARD)
                ? ' border-purple text-gray-900'
                : ' border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700')
            }
            aria-current={
              location.pathname.endsWith(item.href) ||
              (location.pathname.endsWith('/') &&
                item.href === ROUTES.DASHBOARD)
                ? 'page'
                : undefined
            }
          >
            {item.name}
          </Link>
        )
      )}
    </div>
  )
}

export default HeaderLinks
