import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import { LOCAL_STORAGE_KEYS, DEFAULT_LANGUAGE } from './constants'

//const userLanguage = window.navigator.language
const defaultLang =
  localStorage.getItem(LOCAL_STORAGE_KEYS.LANG) || DEFAULT_LANGUAGE

i18n
  .use(Backend)
  //.use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: defaultLang,
    fallbackLng: DEFAULT_LANGUAGE,
    debug: false, //process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false,
    },
  })

document.documentElement.lang = defaultLang

export default i18n
