const Loader = (props) => {
  return (
    <div className={'lds-ring' + (props.isLarge ? ' is-large' : '')}>
      <div></div>
      <div></div>
      {/* <div></div>
      <div></div> */}
    </div>
  )
}
export default Loader
