import { Route } from 'react-router-dom'
import { MIN_WIDTH } from './constants'
import { useWindowWidth } from './helpers'
import { ScreenSizeError } from './components/ScreenSizeError'

const ScreenSizeErrorRoute = ({ component: Component, ...rest }) => {
  const size = useWindowWidth()

  return (
    <Route
      {...rest}
      render={(props) => {
        if (size > MIN_WIDTH) {
          return <Component {...props} />
        } else {
          return <ScreenSizeError />
        }
      }}
    />
  )
}
export default ScreenSizeErrorRoute
