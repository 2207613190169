import React, { useState } from 'react'
import { LOCAL_STORAGE_KEYS } from '../constants'

export const AuthenticationContext = React.createContext<{
  token: string
  login: (token: string) => void
  logout: () => void
}>({ token: undefined, login: (token: string) => {}, logout: () => {} })

const storedToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)

const Authentication = (props: any) => {
  const [token, setToken] = useState<string>(storedToken)

  const login = (token: string) => {
    setToken(token)
    localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token)
  }

  const logout = () => {
    setToken(undefined)
  }

  return (
    <AuthenticationContext.Provider value={{ token, login, logout }}>
      {props.children}
    </AuthenticationContext.Provider>
  )
}

export default Authentication
