import styled from 'styled-components'
import WidthErrorImg from '../theme/images/width-error-image.png'
import { useTranslation } from 'react-i18next'

const ImgContainer = styled.div`
  margin-top: 91px;
  width: 180px;
  height: 180px;
  background-color: white;
  border-radius: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`

const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 44px 140px;
  flex-flow: column;

  @media screen and (max-width: 550px) {
    margin: 44px 44px;
  }
`

const MessageTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.16;
  letter-spacing: 1.76px;
  color: ${(props) => props.theme.colors.purple};
  margin-bottom: 1em;
`

const MessageSubtitle = styled.p`
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: 1.43px;
  text-align: center;
  color: #000;
  margin-top: 8px;
`

export const ScreenSizeError = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col justify-center items-center">
      <ImgContainer>
        <img src={WidthErrorImg} alt="Not supported device width" />
      </ImgContainer>
      <MessageContainer>
        <MessageTitle>{t('widthErrorScreen.title')}</MessageTitle>
        <MessageSubtitle>{t('widthErrorScreen.subtitle')}</MessageSubtitle>
      </MessageContainer>
    </div>
  )
}
