import { useEffect, useState } from 'react'
import { SERVICES } from '../constants'
import {
  axiosAuthGet,
  axiosAuthPatch,
  axiosAuthPost,
  showError,
} from '../helpers'

const getSystemParameters = (signal?: AbortSignal) => {
  return axiosAuthGet(
    SERVICES.GET_CONFIGURATION,
    { 'Cache-Control': 'no-cache' },
    signal
  )
}

const getTemplates = (signal?: AbortSignal) => {
  return axiosAuthGet(SERVICES.GET_TEMPLATES, signal)
}

const updateSystemParameters = (values: any) => {
  return axiosAuthPatch(SERVICES.GET_CONFIGURATION, values)
}

const updateTemplate = (type: string, language: string, values: any) => {
  return axiosAuthPatch(SERVICES.GET_TEMPLATES + '/' + type + '/' + language, {
    changes: values,
  })
}

const createTemplate = (payload: any) => {
  return axiosAuthPost(SERVICES.GET_TEMPLATES, payload)
}

export const systemParametersService = {
  getSystemParameters,
  getTemplates,
  updateSystemParameters,
  updateTemplate,
  createTemplate,
}

export const useSystemParameters = () => {
  const [configurationData, setConfigurationData] = useState()
  const [templateData, setTemplateData] = useState({})

  useEffect(() => {
    const controller = new AbortController()

    systemParametersService
      .getSystemParameters(controller.signal)
      .then((res) => {
        setConfigurationData(res.data)
      })
      .catch((err) => showError('configuration.loadError', err))

    systemParametersService
      .getTemplates(controller.signal)
      .then((res) => {
        setTemplateData(res.data.templates)
      })
      .catch((err) => showError('configuration.loadError', err))

    return () => controller.abort()
  }, [])

  return [configurationData, templateData]
}
