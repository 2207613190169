//import config from '../config'
import {
  //handleResponse,
  axiosAuthPost,
  axiosRefreshAuthPost,
  axiosPost,
  axiosGet,
  axiosAuthGet,
  setToken,
  getToken,
  removeToken,
  getAuthHeader,
  axiosAuthDelete,
} from '../helpers'
import {
  LOCAL_STORAGE_KEYS,
  ROUTES,
  SERVICES,
  STATUS_CODES,
} from '../constants'
import { UserResponse } from '../model/authentication'
import { useEffect, useState } from 'react'
import moment from 'moment'

const setPassword = (newPassword: string) => {
  return axiosAuthPost(SERVICES.CHANGE_PSSW, { newPassword })
}

const changePassword = (
  oldPassword: string,
  newPassword: string,
  authenticate?: any
) => {
  const { token, next_page } = authService

  if (!token || !token.accessToken) {
    return
  }

  if (next_page !== ROUTES.CHANGE_PSSW) {
    return axiosAuthPost(SERVICES.CHANGE_PSSW, { oldPassword, newPassword })
  }

  return axiosAuthPost(
    SERVICES.CHANGE_PSSW,
    { oldPassword, newPassword },
    { ...getAuthHeader(token.accessToken) }
  ).then((res) => {
    setToken(LOCAL_STORAGE_KEYS.NEXT_PAGE, 'all')
    authenticate(JSON.stringify({ accessToken: res?.data?.accessToken }))
    fetchUser()
    return res
  })
}

const deactivate = (password: string) => {
  return axiosAuthPost(SERVICES.DEACTIVATE, { password })
}

const forgotPassword = (email: string) => {
  return axiosPost(SERVICES.FOROT_PSSW, { email })
}

const login = (username: string, password: string) => {
  return axiosPost(SERVICES.LOGIN, { username, password }).then((res) => {
    setToken(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, {
      accessToken: res?.data?.accessToken,
    })
    if (res?.data?.mfaSetupRequired) {
      setToken(LOCAL_STORAGE_KEYS.NEXT_PAGE, ROUTES.TWO_FACTOR)
    } else {
      setToken(LOCAL_STORAGE_KEYS.NEXT_PAGE, ROUTES.VERIFY)
    }
    return res
  })
}

const logout = async (logout?: any) => {
  return Promise.resolve('Logged').then(() => {
    if (!!logout) {
      axiosAuthPost(SERVICES.LOGOUT).then(() => logout())
    }
    removeToken(LOCAL_STORAGE_KEYS.USER)
    removeToken(LOCAL_STORAGE_KEYS.NEXT_PAGE)
    removeToken(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
    removeToken(LOCAL_STORAGE_KEYS.CONFIGURATION)
    removeToken(LOCAL_STORAGE_KEYS.CONFIGURATION_TAB)
    removeToken(LOCAL_STORAGE_KEYS.COLOR_SESSION)
    removeToken(LOCAL_STORAGE_KEYS.SYSTEM_PARAMETERS)
    removeToken(LOCAL_STORAGE_KEYS.TRANSLATIONS_SESSION)
    removeToken(LOCAL_STORAGE_KEYS.LANGUAGE_SESSION)
    window.dispatchEvent(new Event('updateUser'))
  })
}

const mfaSetup = () => {
  const { token } = authService
  if (!token || !token.accessToken) {
    return
  }

  return axiosAuthPost(
    SERVICES.TWO_FACTOR,
    {},
    { ...getAuthHeader(token.accessToken) }
  )
}

const refreshBackupCodes = () => {
  const { token } = authService
  if (!token || !token.accessToken) {
    return
  }

  return axiosAuthPost(
    SERVICES.REFRESH_BACKUP_CODES,
    {},
    { ...getAuthHeader(token.accessToken) }
  )
}

const newPassword = (newPassword: string, token: string = '') => {
  return axiosAuthPost(
    SERVICES.NEW_PSSW,
    { newPassword },
    getAuthHeader(token)
  ).then(() => removeToken(LOCAL_STORAGE_KEYS.AUTH))
}

const refreshToken = () => {
  return axiosRefreshAuthPost(SERVICES.REFRESH_TOKEN).then((res) => {
    const { accessToken } = res.data
    const auth = getToken(LOCAL_STORAGE_KEYS.AUTH)
    setToken(LOCAL_STORAGE_KEYS.AUTH, { ...auth, accessToken })
  })
}

const register = (username: string) => {
  return axiosPost(SERVICES.REGISTER, { username })
}

const resendCode = (username: string) => {
  return axiosAuthPost(SERVICES.RESEND_OTP, { username })
}

const verifyCode = (verificationCode: string, authenticate: any) => {
  const { token } = authService

  if (!token || !token.accessToken) {
    return
  }
  return axiosPost(
    SERVICES.VERIFY_OTP,
    { otp: verificationCode },
    { ...getAuthHeader(token.accessToken) }
  ).then((res) => {
    if (res?.data?.passwordChangeRequired) {
      setToken(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, {
        accessToken: res?.data?.accessToken,
      })
      setToken(LOCAL_STORAGE_KEYS.NEXT_PAGE, ROUTES.CHANGE_PSSW)
    } else {
      setToken(LOCAL_STORAGE_KEYS.NEXT_PAGE, 'all')
      authenticate(JSON.stringify({ accessToken: res?.data?.accessToken }))
      fetchUser()
    }
    return res
  })
}

const activateMfa = (verificationCode: string, authenticate: any) => {
  const { token } = authService
  if (!token || !token.accessToken) {
    return
  }
  return axiosPost(
    SERVICES.VERIFY_OTP,
    { otp: verificationCode },
    { ...getAuthHeader(token.accessToken) }
  ).then((res) => {
    if (res?.data?.passwordChangeRequired) {
      setToken(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, {
        accessToken: res?.data?.accessToken,
      })
      setToken(LOCAL_STORAGE_KEYS.NEXT_PAGE, ROUTES.CHANGE_PSSW)
    } else {
      setToken(LOCAL_STORAGE_KEYS.NEXT_PAGE, 'all')
      authenticate(JSON.stringify({ accessToken: res?.data?.accessToken }))
      fetchUser()
    }
    return res
  })
}

interface ExportPayload {
  minTimestamp: string
  maxTimestamp: string
  format: string
  storage: string
  eventTypes: string[]
  recipients: string[]
}

const exportEvents = (payload: ExportPayload) => {
  return axiosAuthPost(SERVICES.EXPORT_EVENTS, payload)
}

export interface AddUserPayload {
  username: string
  firstname: string
  lastname: string
  password: string
  email: string
  role: string
}

const addUser = (payload: AddUserPayload) => {
  return axiosAuthPost(SERVICES.USERS, payload)
}

const getUsers = (signal?: AbortSignal) => {
  return axiosAuthGet(SERVICES.USERS, signal)
}

const deleteUser = (username: string) => {
  return axiosAuthDelete(SERVICES.USERS + '/' + username)
}

interface MfaData {
  backupCodes: string[]
  hash: string
  hashUri: string
}

export const useMultiFactorAuth = (): [MfaData, string, boolean, boolean] => {
  const [mfaData, setMfaData] = useState<MfaData>({
    backupCodes: [],
    hash: '',
    hashUri: '',
  })

  const [date, setDate] = useState('')

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const controller = new AbortController()

    authService
      .mfaSetup()
      .then((res) => {
        setMfaData((prevState) => ({
          ...prevState,
          backupCodes: res?.data?.mfaBackupCodes,
          hash: res?.data?.mfaHash,
          hashUri: res?.data?.mfaHashUri,
        }))
        setDate(moment().format('DD MMM YYYY'))
      })
      .catch((err) => {
        setError(true)
      })
      .finally(() => {
        setLoading(false)
        // @ts-ignore
        document.querySelector(`#code`)?.focus()
      })

    return () => controller.abort()
  }, [])

  return [mfaData, date, error, loading]
}

export const authService = {
  changePassword,
  setPassword,
  deactivate,
  forgotPassword,
  login,
  logout,
  newPassword,
  refreshToken,
  register,
  resendCode,
  verifyCode,
  mfaSetup,
  refreshBackupCodes,
  activateMfa,
  exportEvents,
  addUser,
  getUsers,
  deleteUser,
  get token() {
    return getToken(LOCAL_STORAGE_KEYS.ACCESS_TOKEN)
  },
  get next_page() {
    return getToken(LOCAL_STORAGE_KEYS.NEXT_PAGE)
  },
}

// TODO Move UI Service to separate file
const fetchUIConfig = () => {
  const uiConfig = getToken(LOCAL_STORAGE_KEYS.UI_CONFIG)

  if (uiConfig) {
    return Promise.resolve(uiConfig)
  }

  return axiosGet(SERVICES.UI_CONFIG).then((res) => {
    if (res.status === STATUS_CODES.SUCCESS) {
      setToken(LOCAL_STORAGE_KEYS.UI_CONFIG, res.data)
      return res.data
    }
    return res
  })
}

const fetchUser = () => {
  const user = getToken(LOCAL_STORAGE_KEYS.USER)

  if (user) {
    return Promise.resolve(user)
  }

  return axiosAuthGet(SERVICES.USER).then((res) => {
    if (res.status === STATUS_CODES.SUCCESS) {
      setToken(LOCAL_STORAGE_KEYS.USER, res.data)
      window.dispatchEvent(new Event('updateUser'))
      return res.data
    }
    return res
  })
}

const fetchUserOptions = async () => {
  const userOptions = getToken(LOCAL_STORAGE_KEYS.USER_OPTIONS)

  if (userOptions) {
    return Promise.resolve(userOptions)
  }

  const res = await axiosAuthGet(SERVICES.USER_OPTIONS)
  if (res.status === STATUS_CODES.SUCCESS) {
    setToken(LOCAL_STORAGE_KEYS.USER_OPTIONS, res.data)
    return res.data
  }
  return res
}

const postUserOptions = (flowId: any, data: string) => {
  return axiosAuthPost(SERVICES.POST_USER_OPTIONS, { flowId, data }).then(() =>
    removeToken(LOCAL_STORAGE_KEYS.USER_OPTIONS)
  )
}

const postUserHistory = (body: any) => {
  return axiosAuthPost(SERVICES.HISTORY, { body })
}

const getUserHistory = () => {
  return axiosAuthGet(SERVICES.HISTORY).then((res) => {
    if (res.status === STATUS_CODES.SUCCESS) {
      setToken(LOCAL_STORAGE_KEYS.HISTORY, res.data)
      return res.data
    }
    return res
  })
}

const getUser = (): UserResponse => {
  return getToken(LOCAL_STORAGE_KEYS.USER)
}

export const UIService = {
  fetchUIConfig,
  fetchUser,
  fetchUserOptions,
  postUserOptions,
  getUser,
  getUserHistory,
  postUserHistory,
  get user() {
    return getToken(LOCAL_STORAGE_KEYS.USER)
  },
}
