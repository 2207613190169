import styled, { createGlobalStyle } from 'styled-components'
import Grid from 'styled-components-grid'
import {
  StyledWrapperProps,
  GlobalStyleProps,
  ButtonStyleProps,
  StyledColumnProps,
} from '../model/styles'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${(props: GlobalStyleProps) => props.theme.fonts.fontFamily};
    font-style: normal;
    font-weight: 400;
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
`

const InputFieldContainer = styled.div`
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Button = styled.button.attrs((props: ButtonStyleProps) => ({
  type: props.type || 'button',
}))`
  background-color: ${(props: ButtonStyleProps) =>
    props.theme.colors.primaryLight};
  color: ${(props: ButtonStyleProps) => props.theme.colors.white};
  border: 0px solid ${(props: ButtonStyleProps) => props.theme.colors.primary};
  box-sizing: border-box;
  border-radius: 6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  padding: 8px 15px;
  margin: 10px 0;
  cursor: pointer;
  width: 180px;

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: ${(props: ButtonStyleProps) =>
      props.theme.colors.primaryLightDisabled};
  }
`

const MenuItem = styled.li`
  margin-bottom: 10px;

  &:last-of-type {
    margin: 0;
  }

  button {
    background: transparent;
    border: none;
    color: ${(props) => props.theme.menuTextColor};
    font-size: 13px;
    line-height: 15px;
    cursor: pointer;
  }
`

const Heading = styled.div`
  color: ${(props: GlobalStyleProps) => props.theme.colors.black};
  font-weight: 300;
  font-size: 38px;
  line-height: 45px;
  margin: 10px 0px 0px 0px;
`

const Caption = styled.div`
  color: ${(props: GlobalStyleProps) => props.theme.colors.black};
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  margin: 10px 0;
`

const LinkButton = styled.button.attrs((props: ButtonStyleProps) => ({
  type: props.type || 'button',
}))`
  color: ${(props: ButtonStyleProps) =>
    !!props.color ? props.color : props.theme.colors.primary};
  background: transparent;
  border: none;
  text-decoration: underline;
  font-size: ${(props: ButtonStyleProps) =>
    !!props.fontSize ? props.fontSize : 9}px;
  cursor: pointer;
  margin: 10px 0;
`

const StyledInput = styled.input`
  color: ${(props: GlobalStyleProps) => props.theme.colors.black};
  border-bottom: solid 1px
    ${(props: GlobalStyleProps) => props.theme.colors.black};
`

const ErrorMessage = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: ${(props: GlobalStyleProps) => props.theme.colors.dangerRed};
  margin: 10px 0;
  display: inline-block;
  width: 100%;
`

const MainContainer = styled.div`
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  flex: 1;
  background-color: ${(props: GlobalStyleProps) => props.theme.colors.paleGrey};
`

const StyledGrid = styled(Grid)``

const StyledColumn = styled(Grid.Unit)`
  padding: 0
    ${(props: StyledColumnProps) => props.theme.gutters.column.sm / 2}px;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;

  @media (min-width: ${(props: StyledColumnProps) =>
      props.theme.breakpoints.md}px) {
    padding: 0
      ${(props: StyledColumnProps) => props.theme.gutters.column.md / 2}px;
  }

  @media (min-width: ${(props: StyledColumnProps) =>
      props.theme.breakpoints.lg}px) {
    padding: 0
      ${(props: StyledColumnProps) => props.theme.gutters.column.lg / 2}px;

    &:first-of-type {
      padding-left: 0;
    }
    &:last-of-type {
      padding-right: 0;
    }
  }

  ${(props: StyledColumnProps) =>
    props.gutter && `padding: ${props.gutter} !important;`}
  ${(props: StyledColumnProps) =>
    props.halign &&
    `
    justify-content: ${props.halign};
    `}
`

const StyledWrapper = styled.div`
  margin: ${(props: StyledWrapperProps) => (props.margin ? props.margin : 0)};
  text-align: ${(props: StyledWrapperProps) =>
    props.align ? props.align : 'left'};
`

export {
  GlobalStyle,
  Button,
  Heading,
  Caption,
  MainContainer,
  InputFieldContainer,
  LinkButton,
  StyledInput,
  ErrorMessage,
  StyledColumn,
  StyledWrapper,
  StyledGrid,
  MenuItem,
}
